import React from "react"
import { AsideLayout } from "components/aside-layout"
import { Seo } from "components/seo"
// import { StaticImage } from "gatsby-plugin-image"
import { DefaultHeader } from "components/headers/default-header"
import { Service } from "components/service"
import { FollowBtn } from "components/follow-btn"

const CouponCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <AsideLayout>
        <Seo
          pagetitle="TwitterインスタントウィンキャンペーンでのWebクーポン配布"
          pagepath={location.pathname}
          pagedesc="Twitterキャンペーンでのクーポン配布なら拡散とEC・店舗集客の両方が狙えます。お得に手間なく来店喚起がしたい担当者向けに、キャンペーンの流れ・ツール導入のメリット・豊富な機能・料金について説明します。"
        />
        <main className="coupon">
          <section className="fv pt-8 px-4 md:px-11 md:pt-14 relative">
            <div>
              <div className="flex gap-3 mb-3">
                <p className="bg-coup_sec text-center py-2 px-3 font-bold rounded-lg text-coupon md:text-2xl">
                  印刷コストを大幅削減
                </p>
                <p className="bg-coup_sec text-center py-2 px-3 font-bold rounded-lg text-coupon md:text-2xl">
                  自動抽選
                </p>
              </div>
              <h1 className="font-bold text-white mb-3 md:mb-5">
                SNS上のクーポン配布で
                <br />
                実店舗・ECへの
                <br className="md:hidden block" />
                来店促進！
              </h1>
              <p className="font-bold text-white leading-6 md:text-xl md:leading-8 fv-lead">
                Twitterキャンペーンで管理も簡単な
                <br className="md:hidden block" />
                Webクーポンを配布。
                <br />
                SNSによる拡散効果・集客アップの
                <br className="md:hidden block" />
                両方を狙えます！
              </p>
            </div>
            <div className="md:absolute w-9/12 md:w-6/12 md:right-0 md:bottom-0 ml-auto">
              <img
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/top_coupon.png"
                alt="Twitterキャンペーンで管理も簡単なWebクーポンを配布。SNSによる拡散効果・集客アップの両方を狙えます！"
              />
            </div>
          </section>
          <section className="sec01 bg-white">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="flex flex-col-reverse md:flex-row md:gap-8">
                <div className="md:w-2/5">
                  <img
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/sec01.png"
                    alt="成果の出るクーポンキャンペーンをよりカンタンに"
                  />
                </div>
                <div className="md:w-3/5">
                  <h2 className="text-coup_title text-3xl md:text-4xl font-bold mb-4">
                    成果の出る
                    <br />
                    クーポンキャンペーンを
                    <br />
                    よりカンタンに
                  </h2>
                  <p className="text-serv_text">
                    「SNSから実店舗へ誘導したい」「紙のクーポン配布は手間がかかって大変だった」とお悩みの方におすすめです。作成も管理も簡単なWebクーポンをSNSで効率的に拡散、来店喚起へとつなげます。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="flow">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Flow</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  クーポン配布キャンペーンの流れ
                </p>
              </div>
              <div className="flex md:items-stretch md:gap-16 flex-col md:flex-row gap-y-10 items-center">
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="Twitterでフォロー＆リツイートしてキャンペーンに参加"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step01.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    Twitterでフォロー＆リツイートしてキャンペーンに参加
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="DMでクーポンURLを通知"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step02.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    DMでクーポンURLを通知
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="クーポンを受け取り利用ができます"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step03.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    クーポンを受け取り利用ができます
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 w-full">
                  <div className="px-8 md:px-0">
                    <img
                      layout="constrained"
                      alt="クーポン利用を通して、認知・集客アップが期待できます"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step04.png"
                      className="mb-8"
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    クーポン利用を通して、認知・集客アップが期待できます
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="merit">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Merit</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  PARKLoT利用のメリット
                </p>
              </div>
              <div className="mb-8">
                <h3 className="merit-subtitle text-serv_text text-xl font-bold mb-2 before:content-['01']">
                  月額制でクーポン発行が無制限
                </h3>
                <p className="text-serv_text">
                  月額5.5万円（税込）から、お得に何度でもキャンペーンを開催できます。
                  <br />
                  2種類のクーポンで、より魅力的なプラン設計が可能。紙クーポンの印刷コストも抑えられます。
                </p>
              </div>
              <div className="flex flex-col md:flex-row md:justify-between gap-4 md:gap-8 mb-20">
                <div className="bg-white bg-opacity-40 p-4 rounded-lg w-full">
                  <h4 className="bg-coupon text-center font-bold text-white px-3 py-1 mb-3 rounded">
                    消し込み式
                  </h4>
                  <div className="text-center mb-3">
                    <img
                      style={{ display: "inline" }}
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/merit01.png"
                      alt="消し込み式クーポン"
                      width={228}
                    />
                  </div>
                  <p className="text-serv_text">
                    クーポンを表示してから一定時間以内に店舗でスタッフが画面上にあるボタンをタップし、利用確認することで複数回の利用を防ぐことができます。一定時間が経過すると自動で利用済みになります。
                  </p>
                </div>
                <div className="bg-white bg-opacity-40 p-4 rounded-lg w-full">
                  <h4 className="bg-coupon text-center font-bold text-white px-3 py-1 mb-3 rounded">
                    期間中無制限
                  </h4>
                  <div className="text-center mb-3">
                    <img
                      style={{ display: "inline" }}
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/merit02.png"
                      alt="期間中無制限"
                      width={228}
                    />
                  </div>
                  <p className="text-serv_text">
                    指定期間中に何度でも利用できるので、継続的な利用を促進できます
                  </p>
                </div>
              </div>
              <div className="mb-8">
                <h3 className="merit-subtitle merit-subtitle__2line text-serv_text text-xl font-bold mb-2 before:content-['02']">
                  ツイート投稿〜抽選〜DM送付クーポン配布を一気通貫で手間要らず{" "}
                </h3>
                <p className="text-serv_text">
                  PARKLoT管理画面から設定しておくだけで、時間通りに自動でキャンペーン開始。面倒な抽選やクーポン発送もすべて自動化でき、たいへん効率的です。
                  <br />
                  繰り返しの開催で、さらなる集客を目指せます。
                </p>
              </div>
            </div>
          </section>
          <section className="feature">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Feature</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">機能</p>
              </div>
              <div className="flex flex-wrap gap-4">
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <img
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon.png"
                        className="md:w-20 w-14"
                        alt="クーポン作成"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      クーポン作成
                    </h3>
                    <p className="text-serv_text">
                      消し込み式・時限式・期間中無限の3種類のクーポンを作成できます。QRコードを表示させることも可能です。
                      <br />
                      <span className="font-bold text-coupon">
                        クーポン画面の表示画像
                      </span>
                      <br />
                      <span className="font-bold text-coupon">
                        クーポンコード/QRコード/外部URL
                      </span>
                      <br />
                      を共有いただくだけで設定完了です。
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <img
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/lottery.png"
                        className="md:w-20 w-14"
                        alt="自動抽選"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      自動抽選
                    </h3>
                    <p className="text-serv_text">
                      クーポンの種類と当選者数を事前に設定するだけで、フォロー＆リツイートしたユーザーを自動抽選します。1名〜参加者全員まで、当選者数の上限設定を行ったり、当選条件にフィルターをかけることもできるため、より効率的な拡散が見込めます。
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <img
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/email.png"
                        className="md:w-20 w-14"
                        alt="自動送付"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      自動送付
                    </h3>
                    <p className="text-serv_text">
                      自動抽選後、当選者へのDM送信も自動で行う事ができます。インスタントウィンで結果がその場でわかるため、応募者が離脱しにくく、ECサイトや実店舗へより誘導しやすくなります。
                      <br />
                      <span className="font-bold text-coupon">
                        回収率19％の実績あり
                      </span>
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <img
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/reserve.png"
                        className="md:w-20 w-14"
                        alt="ツイート予約投稿"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      ツイート予約投稿
                    </h3>
                    <p className="text-serv_text">
                      管理画面から各種データを設定しておけば、指定時刻に自動的にツイートが投稿されます。
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <img
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/csv.png"
                        className="md:w-20 w-14"
                        alt="応募者リストCSVデータ"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      応募者リストCSVデータ
                    </h3>
                    <p className="text-serv_text">
                      企業アカウントをフォローし、該当ツイートをリツイートした応募者のデータをCSVでお渡しできます。発行済みクーポンの使用状況なども確認できるため、施策の振り返り・次回立案に効果的です。{" "}
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <img
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/follower.png"
                        className="md:w-20 w-14"
                        alt="フォロワー数管理"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      フォロワー数管理
                    </h3>
                    <p className="text-serv_text">
                      管理画面からフォロワーの推移をチャート形式で確認することができます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="price bg-coup_sec">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Price</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">料金</p>
              </div>
              <div className="flex flex-col gap-4 md:gap-10 md:flex-row">
                <div className="bg-white rounded-lg w-full">
                  <h3 className="bg-coupon text-center rounded-t-lg py-2 font-bold text-white text-xl">
                    基本料金
                  </h3>
                  <div className="p-4 md:p-6">
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        初期費用
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">8</span>万円〜
                      </p>
                    </div>
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        月額
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">5</span>万円〜
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        合計
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">13</span>
                        万円〜（税込143,000円〜）
                      </p>
                    </div>
                    <p className="text-xs text-serv_subt">
                      ※ハッシュタグ収集、ギフトコードのプレゼントキャンペーンも実施可能
                    </p>
                  </div>
                </div>
                <div className="bg-white rounded-lg w-full">
                  <h3 className="bg-serv_subt text-center rounded-t-lg py-2 font-bold text-white text-xl">
                    オプション
                  </h3>
                  <div className="p-4 md:p-6">
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        リプライ収集
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">3</span>
                        万円〜
                      </p>
                    </div>
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        いいね収集
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">3</span>
                        万円〜
                      </p>
                    </div>
                    <div>
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        キャンペーンLP作成代行
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">10</span>
                        万円〜
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <Service />
        </main>
      </AsideLayout>
      <FollowBtn />
    </>
  )
}

export default CouponCampaign
